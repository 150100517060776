import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsClient } from "@uidotdev/usehooks";
import dynamic from "next/dynamic";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useJobApplication from "../../../hooks/useJobApplication";
import {
  clearJobList,
  updateSelectedJob,
} from "../../../redux/actions/job_action";
import SharedNavbar2 from "../../shared/SharedNavbar/Navbar/Navbar";
import SharedToast from "../../shared/SharedToast/SharedToast";
import AccordionForYou from "../AccordionForYou/AccordionForYou";
import AccordionJobList from "../AccordionJobList/AccordionJobList";
import JobListNavigation from "../JobListNavigation/JobListNavigation";
import JobSearchOptions from "../JobSearchOptions/JobSearchOptions";
import { Wrapper } from "./styles";

const CreateResumePopup = dynamic(
  () =>
    import(
      "../../profile/ResumePopups/ApplyJobResumeStatus/CreateResume/CreateResume"
    ),
  { ssr: false }
);
const RejectedResumePopup = dynamic(
  () =>
    import(
      "../../profile/ResumePopups/ApplyJobResumeStatus/RejectedResume/RejectedResume"
    ),
  { ssr: false }
);
const SharedNationalityDialog = dynamic(
  () => import("../../shared/SharedNationalityDialog/SharedNationalityDialog"),
  { ssr: false }
);
const JobAlertDialog = dynamic(
  () => import("../JobAlertDialog/JobAlertDialog"),
  { ssr: false }
);
const JobCountryPreferenceDialog = dynamic(
  () => import("../JobCountryPreferenceDialog/JobCountryPreferenceDialog"),
  { ssr: false }
);
const PostApplyDialog = dynamic(
  () => import("../PostApplyDialog/PostApplyDialog"),
  { ssr: false }
);
const JobAlertsCreateDialog = dynamic(
  () => import("../../profile/JobAlertsCreateDialog/JobAlertsCreateDialog"),
  { ssr: false }
);
const JobAlertsNotificationDialog = dynamic(
  () =>
    import(
      "../../profile/JobAlertsNotificationDialog/JobAlertsNotificationDialog"
    ),
  { ssr: false }
);
const VisitorJobAlertDialog = dynamic(
  () => import("../VisitorJobAlertDialog/VisitorJobAlertDialog"),
  { ssr: false }
);
const VisitorJobAlertSuccesDialog = dynamic(
  () =>
    import(
      "../VisitorJobAlertDialog/VisitorJobAlertSuccessDialog/VisitorJobAlertSuccessDialog"
    ),
  { ssr: false }
);

export default function JobListWrapper(props) {
  const { serverJobs, ssrFilterParams, ssrPageParams, currentTab } = props;

  const dispatch = useDispatch();

  const isClient = useIsClient();
  const isDesktop = useMediaQuery("(min-width: 1280px)");

  const [selectedTab, setSelectedTab] = useState(currentTab);

  const alertBanner = useSelector((state) => state.dialog.showAlertBanner);

  const jobOrigin = "job_list";
  const jobDiscovery = "search";

  const {
    applyJobFunc,
    checkLoggedIn,
    checkNationalityPhoneNumberLocation,
    checkRightToWorkAndRelocate,
    checkResumeRejected,
    checkScrapedJob,
  } = useJobApplication(jobOrigin, jobDiscovery);

  function triggerToast(toastMessage, state) {
    toast(() => toastMessage, {
      className: state ? "Toast-success" : "Toast-error",
    });
  }

  function handleTabNavClick(tab) {
    if (tab === selectedTab) {
      return;
    }

    setSelectedTab(tab);
    dispatch(clearJobList());
  }

  async function onApplyJobClick(e, job) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    dispatch(updateSelectedJob(job));
    // NOT LOGGED IN
    if (checkLoggedIn(job)) {
      sendTrackingEvent({
        event: "applied-job",
        "job-title": job.title,
        resume: false,
        logged_in: false,
        scraped: job.scraped !== "",
        "job-bucket-id": null,
        "job-id": job?.id,
      });
      return;
    }
    // CONDITION - NO NATIONALITY OR MOBILE NUMBER OR CURRENT LOCATION
    if (checkNationalityPhoneNumberLocation(job)) {
      return;
    }

    // CONDITION - RIGHT TO WORK OR RELOCATION PREFERENCE
    if (checkRightToWorkAndRelocate(job)) {
      return;
    }
    // CONDITION - NO RESUME / RESUME REJECTED
    // Do not send in application if user has no resume/rejected
    if (checkResumeRejected(job)) {
      sendTrackingEvent({
        event: "applied-job",
        "job-title": job.title,
        resume: false,
        logged_in: true,
        scraped: job.scraped !== "",
        "job-bucket-id": null,
        "job-id": job?.id,
      });
      return;
    }

    // CONDITION - SCRAPED JOB
    if (checkScrapedJob(job)) {
      sendTrackingEvent({
        event: "CE_job-apply",
        origin: "job_list",
        "job-discovery": jobDiscovery,
        "job-title": job.title,
        "job-id": job?.id,
        resume: false,
        logged_in: true,
        scraped: job.scraped !== "",
        job_preference_toggle: "off",
        "job-bucket-id": null,
      });
      return;
    }

    const params = {
      jobId: job.id,
      canCancel: false,
      origin: jobOrigin,
      jobDiscovery: jobDiscovery,
    };

    const response = await applyJobFunc(job, params);

    if (response.success) {
      sendTrackingEvent({
        event: "CE_job-apply",
        origin: "job_list",
        "job-discovery": jobDiscovery,
        "job-title": job.title,
        "job-id": job?.id,
        resume: true,
        logged_in: true,
        scraped: job.scraped !== "",
        job_preference_toggle: "off",
        "job-bucket-id": response?.payload?.jobBucketId ?? null,
      });
    }
    dispatch(updateSelectedJob({}));
  }

  return (
    <Fragment>
      <SharedNavbar2 page={"job-list-page"} />
      <Wrapper alertBanner={alertBanner} selectedTab={selectedTab}>
        <JobListNavigation
          selectedTab={selectedTab}
          handleTabNavClick={handleTabNavClick}
        />
        {/* create dedicate space for ssr loading for prevent layout shift */}
        {!isClient ? <div style={{ marginTop: "80px" }}></div> : null}
        {!isDesktop && selectedTab === "job-list" ? <JobSearchOptions /> : null}
        {selectedTab === "for-you" ? (
          <AccordionForYou
            jobListTab={selectedTab}
            jobDiscovery={"rec_profile"}
            handleTabChange={handleTabNavClick}
          />
        ) : null}
        {selectedTab === "job-list" ? (
          <AccordionJobList
            serverJobs={serverJobs}
            ssrFilterParams={ssrFilterParams}
            ssrPageParams={ssrPageParams}
            onApplyJobClick={onApplyJobClick}
          />
        ) : null}
      </Wrapper>
      {/* Popup components */}
      {isClient ? (
        <>
          <SharedToast />
          <CreateResumePopup />
          <RejectedResumePopup />
          <PostApplyDialog
            page={currentTab}
            handleTabChange={handleTabNavClick}
          />
          <JobAlertDialog />
          <SharedNationalityDialog jobOrigin={"job_list"} />
          <JobCountryPreferenceDialog
            onApplyJobClicked={onApplyJobClick}
            useLocalApply={true}
            origin={"job_list"}
          />
          <JobAlertsCreateDialog
            selectedCountry={"my"}
            triggerToast={triggerToast}
          />
          <JobAlertsNotificationDialog selectedCountry={"my"} />
          <VisitorJobAlertDialog />
          <VisitorJobAlertSuccesDialog />
        </>
      ) : null}
    </Fragment>
  );
}
