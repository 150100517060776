import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsClient } from "@uidotdev/usehooks";
import { throttle } from "lodash";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formJobQueryParam } from "../../../functions/job";
import {
  calibrateSalary,
  getGaSessionID,
} from "../../../helpers/data_management";
import {
  convertIdsToUrl,
  convertUrlToIds,
  decodePage,
  encodePage,
} from "../../../helpers/filter_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  clearJobList,
  getExperienceLevels,
  getJobs,
  getJobsParams,
  getSpecialisation,
  getStateRegions,
  postGaSessionId,
  updateHoverJob,
  updateJobListFilter,
  updateJobListPage,
  updateJobSearchKeyword,
  updateLoadingJobs,
} from "../../../redux/actions/job_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import * as jobTypes from "../../../redux/types/job_type";
import AccordionJobCard from "../AccordionJobCard/AccordionJobCard";
import AiJobSummary from "../AiJobSummary/AiJobSummary";
import JobAlertToggle from "../JobAlertToggle/JobAlertToggle";
import JobListNoResult from "../JobListNoResult/JobListNoResult";
import JobMobileAppAdCard from "../JobMobileAppAdCard/JobMobileAppAdCard";
import JobWorkPersonaCard from "../JobWorkPersonaCard/JobWorkPersonaCard";
import {
  AccordionJobListSkeleton,
  AccordionMobileJobListSkeleton,
} from "../Skeleton/Skeleton";
import {
  ChevronLeftIconStyled,
  ChevronRightIconStyled,
  EmptyContainer,
  IconButtonStyled,
  JobListContainer,
  PageNumber,
  PaginationNumberBox,
  Wrapper,
} from "./styles";

export default function AccordionJobList(props) {
  const { serverJobs, ssrFilterParams, ssrPageParams, onApplyJobClick } = props;

  const dispatch = useDispatch();
  const router = useRouter();

  const isClient = useIsClient();
  const isMobile = useMediaQuery("(max-width: 640px)");

  const isLoggedIn = checkAuthentication();

  const firstTimeMountLoadingJobs = useRef(true);

  const [renderedJobList, setRenderedJobList] = useState(serverJobs);
  const [filterParams, setFilterParams] = useState(ssrFilterParams);
  const [pageParams, setPageParams] = useState(ssrPageParams);

  // User Related Items
  const user = useSelector((state) => state.user?.user);

  const hasMobileApp = user?.hasMobileApp;
  const workPersonaStatus = user?.workPersonaStatus;

  // Filter Related Items
  const specialisationList = useSelector((state) => state.jobs?.specialisation);
  const stateList = useSelector((state) => state.jobs?.stateRegions);
  const jobTypeList = useSelector((state) => state.jobs?.jobTypes);
  const experienceLevelList = useSelector(
    (state) => state.jobs?.experienceLevels
  );

  // Jobs Related Items
  const isLoadingJobs = useSelector((state) => state.jobs?.isLoadingJobs);
  const fetchingJobs = useSelector((state) => state.jobs.fetchingJobList);
  const jobList = useSelector((state) => state.jobs?.jobs);

  const loadingJobs = fetchingJobs || isLoadingJobs;

  const searchType = useSelector((state) => state.jobs.searchType);
  const jobListFilter = useSelector((state) => state?.jobs?.jobListFilter);

  // DYNAMIC CONST LOGIC
  const searchTerm = jobListFilter?.keyword;
  const showMobileAppBanner = isLoggedIn && !hasMobileApp;
  const showWorkPersonaCard = isLoggedIn && hasMobileApp && !workPersonaStatus;

  // FUNCTION LOGIC
  function onHoverEnter(job) {
    dispatch(updateHoverJob(job));
  }

  const throttledOnHoverEnter = throttle(onHoverEnter, 175);

  // Clears hover state when leaving job card
  function onHoverLeave() {
    dispatch(updateHoverJob({}));
  }

  // handle trigger ga session id
  function handleTriggerGaSessionId(job) {
    const gaSessionId = getGaSessionID();

    if (gaSessionId) {
      dispatch(
        postGaSessionId({
          jobId: job?.id,
          sessionId: gaSessionId,
        })
      );
    }
  }

  /*
   * Handles job card click events including:
   * - Analytics tracking
   * - URL generation and navigation
   * - Cross-border job handling
   */

  function onJobCardClick(e, job) {
    e.stopPropagation();
    e.preventDefault();

    handleTriggerGaSessionId(job);

    sendTrackingEvent({
      event: "CE_job-click",
      "job-id": job?.id,
      origin: "job_list",
      "job-discovery": "search",
      search_term: searchTerm ?? "",
    });

    if (origin === "job_list") {
      Cookies.set("click-source", "job_listing_card");
    }

    const url =
      job?.crossedBorder && job?.scraped
        ? job?.crossBorderData?.source_country === "SG" &&
          process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my"
          ? job?.crossBorderData?.source_country_url +
            `?source=my&origin=job_list`
          : job?.crossBorderData?.source_country_url
        : `/jobs/${job?.slug}?origin=job_list`;

    window.open(url);
  }

  /*
   * Handles job application flow including:
   * - User authentication check
   * - Profile completeness validation
   * - Resume verification
   * - Application submission
   * - Analytics tracking
   */

  /**
   * Fetches job listings with optional refresh functionality
   *
   * This function handles:
   * 1. Job data fetching with pagination
   * 2. Loading state management
   * 3. Analytics tracking
   * 4. GraphQL request cancellation
   * 5. Redux state updates
   */
  function fetchJobs(
    refresh = false,
    jobParams = filterParams,
    currentPageParams = pageParams
  ) {
    // Prevent concurrent fetches unless explicitly refreshing
    if (store.getState().jobs.isLoadingJobs && !refresh) return;

    // Set loading state in Redux
    dispatch(updateLoadingJobs(true));

    // Cancel any in-flight GraphQL requests to prevent race conditions
    if (store.getState().axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY]) {
      store
        .getState()
        .axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY].cancel(
          "job list search"
        );
    }

    // Generate unique key to track current request
    const currentLoadingJobsKey = new Date().getTime();
    store.getState().jobs.currentLoadingJobsKey = currentLoadingJobsKey;

    // Build query parameters from current filter state
    const params = formJobQueryParam(jobParams, refresh);

    // Configure pagination settings
    const pagination = {
      first: 30, // Number of items per page
      last: null,
      startCursor: currentPageParams ?? null, // Current page cursor
      endCursor: null, // Next page cursor
    };

    // Handle analytics tracking (skipped on first mount)
    if (firstTimeMountLoadingJobs.current === false) {
      // Process state/region data for analytics
      const preProcessStateRegion =
        params?.stateRegions && Array.isArray(params?.stateRegions)
          ? params.stateRegions.join(", ").length !== 0
            ? params.stateRegions
            : null
          : null;

      // Process experience levels
      const regex = /\d+(\.\d+)?/g;
      const selectedExp =
        experienceLevelList
          .filter((exp) => params?.experienceIds?.includes(parseInt(exp.id)))
          .map((match) =>
            match.title.toLowerCase().replace(/intern|fresh graduate/g, "0")
          ) ?? [];

      const cleanSelectExp = selectedExp.join().match(regex)?.sort() ?? [];

      // Check if any filters are applied
      const hasFilters = !!(
        params?.trackIds?.length > 0 ||
        params?.stateRegions?.length > 0 ||
        params?.jobTypeIds?.length > 0 ||
        params?.experienceIds?.length > 0 ||
        params?.expectedSalary > 0 ||
        params?.globalHire
      );

      // Send analytics event with detailed search parameters
      sendTrackingEvent({
        event: "CE_job_search_jlp",
        "search-term":
          params.keyword && params.keyword !== "*" ? params.keyword : "",
        has_filters: hasFilters ? "true" : "false",
        input_type: searchType || "unknown",
        specialisation: params.trackIds?.join(",") || "",
        states: preProcessStateRegion?.join(",") || "",
        "job-type": params.jobTypeIds?.join(",") || "",
        experience: cleanSelectExp.join("-") || "0",
        salary:
          params.expectedSalary &&
          Number.isInteger(params.expectedSalary) &&
          params.expectedSalary > 0
            ? calibrateSalary(params.expectedSalary).toString()
            : "0",
        "ct-specialization":
          specialisationList
            .filter((track) => params?.trackIds?.includes(parseInt(track.id)))
            .map((match) => match.slug)
            .join(",") || "",
        "ct-job-types":
          jobTypeList
            .filter((type) => params?.jobTypeIds?.includes(parseInt(type.id)))
            .map((match) => match.title)
            .join(",") || "",
        "ct-min-exp": parseInt(cleanSelectExp[0] ?? 0) || 0,
        "ct-max-exp":
          parseInt(cleanSelectExp[cleanSelectExp.length - 1] ?? 0) || 0,
      });
    } else {
      // Mark first mount as complete
      firstTimeMountLoadingJobs.current = false;
    }

    // Store current search parameters in Redux
    dispatch(getJobsParams(params));

    const currentPage = currentPageParams ? decodePage(currentPageParams) : 1;

    // Handle job list fetching with optional clearing
    if (refresh) {
      dispatch(clearJobList({ jobCurrentPage: currentPage }));
    }

    // Return promise chain with proper error handling
    return dispatch(getJobs(params, pagination))
      .then((response) => {
        if (response?.type === "FETCH_JOBS_SUCCEED") {
          triggerSnackbarFunc({
            snackbarMessage: "Your page is now reset to the default search",
            severity: "ashley",
          });
        }
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  useEffect(() => {
    if (isClient && (!loadingJobs || jobList?.length > 0)) {
      setRenderedJobList(jobList);
    }
  }, [isClient, jobList, loadingJobs]);

  useEffect(() => {
    if (router.isReady && !loadingJobs) {
      const latestFilterParams = convertUrlToIds(
        router.query,
        specialisationList,
        stateList
      );

      const latestPage = encodePage(router.query.page);

      setFilterParams(latestFilterParams);
      setPageParams(latestPage);

      dispatch(updateJobListFilter(latestFilterParams));
      dispatch(
        updateJobSearchKeyword({ keyword: latestFilterParams?.keyword })
      );

      scrollTo(0, 0);

      fetchJobs(true, latestFilterParams, latestPage);
    }
  }, [router.asPath, router.isReady]);

  useEffect(() => {
    const currentPage = pageParams ? decodePage(pageParams) : 1;
    dispatch(updateJobListFilter(filterParams));
    dispatch(updateJobListPage(currentPage));
    dispatch(updateJobSearchKeyword({ keyword: filterParams?.keyword }));

    fetchJobs();

    dispatch(getSpecialisation());
    dispatch(getStateRegions());
    dispatch(getExperienceLevels());
  }, [dispatch]);

  return (
    <Wrapper>
      <JobListContainer>
        {isClient && renderedJobList?.length > 0 && (
          <JobAlertToggle jobAlertBannerId={`job-alert-banner-1`} />
        )}
        {loadingJobs && isClient ? (
          Array.from({ length: 10 }).map((_, index) => {
            return isMobile ? (
              <AccordionMobileJobListSkeleton key={index} />
            ) : (
              <AccordionJobListSkeleton key={index} />
            );
          })
        ) : renderedJobList?.length > 0 ? (
          renderedJobList?.map((job, index) => (
            <>
              {index === 4 && isClient && (
                <>
                  {showMobileAppBanner && <JobMobileAppAdCard />}
                  {showWorkPersonaCard && <JobWorkPersonaCard />}
                  {!isLoggedIn && <JobWorkPersonaCard />}
                </>
              )}
              {index === 10 && isClient ? (
                <JobAlertToggle
                  jobAlertBannerId={`job-alert-banner-${index}`}
                />
              ) : index === 20 && isClient ? (
                <JobAlertToggle
                  jobAlertBannerId={`job-alert-banner-${index}`}
                />
              ) : null}

              <AccordionJobCard
                key={job.id}
                job={job}
                onHoverEnter={throttledOnHoverEnter}
                onJobCardClick={onJobCardClick}
                onApplyJobClick={onApplyJobClick}
              />
            </>
          ))
        ) : (
          <JobListNoResult />
        )}
        {isClient && <Pagination />}
      </JobListContainer>
      <AiJobSummary onHoverLeave={onHoverLeave} />
    </Wrapper>
  );
}

/**
 * Pagination Component
 * Handles job list pagination with next/previous navigation
 * Includes:
 * - Page number display
 * - Navigation controls
 * - URL management
 * - Scroll position reset
 */
function Pagination(props) {
  const router = useRouter();

  const isClient = useIsClient();

  const jobListFilter = useSelector((state) => state.jobs.jobListFilter);
  const specialisationList = useSelector((state) => state.jobs?.specialisation);
  const stateList = useSelector((state) => state.jobs?.stateRegions);

  const jobPageInfo = useSelector((state) => state.jobs.jobPageInfoV1);
  const currentPage = useSelector((state) => state.jobs.jobCurrentPage);

  function jobListPreviousPage() {
    const { path, query } = convertIdsToUrl(
      jobListFilter,
      specialisationList,
      stateList
    );
    const newPage = currentPage - 1;

    if (newPage === 1) {
      router.push({ pathname: path, query: query }, undefined, {
        shallow: true,
      });
    }

    const newQuery = query + (query ? "&page=" : "page=") + newPage;

    router.push({ pathname: path, query: newQuery }, undefined, {
      shallow: true,
    });
  }

  function jobListNextPage() {
    const { path, query } = convertIdsToUrl(
      jobListFilter,
      specialisationList,
      stateList
    );
    const newPage = currentPage + 1;

    const newQuery = query + (query ? "&page=" : "page=") + newPage;

    router.push({ pathname: path, query: newQuery }, undefined, {
      shallow: true,
    });
  }

  return (
    <EmptyContainer
      sx={{ width: "100%", marginTop: "25px" }}
      container
      direction="row"
      justifyContent="center"
    >
      {jobPageInfo?.hasPreviousPage ? (
        <Fragment>
          <IconButtonStyled
            $margin_right="true"
            $pagination="true"
            disableRipple
            onClick={() => jobListPreviousPage()}
            disabled={!jobPageInfo?.hasPreviousPage}
          >
            {isClient && <ChevronLeftIconStyled />}
          </IconButtonStyled>
          <PaginationNumberBox $non_focus="true">
            <PageNumber variant="subtitle2">{currentPage - 1}</PageNumber>
          </PaginationNumberBox>
        </Fragment>
      ) : null}

      <PaginationNumberBox $margin="true">
        <PageNumber variant="subtitle2">{currentPage}</PageNumber>
      </PaginationNumberBox>

      {jobPageInfo?.hasNextPage && (
        <Fragment>
          <PaginationNumberBox $non_focus="true">
            <PageNumber variant="subtitle2">{currentPage + 1}</PageNumber>
          </PaginationNumberBox>
          <IconButtonStyled
            $margin_left="true"
            $pagination="true"
            disableRipple
            onClick={() => jobListNextPage()}
            disabled={!jobPageInfo?.hasNextPage}
          >
            {isClient && <ChevronRightIconStyled />}
          </IconButtonStyled>
        </Fragment>
      )}
    </EmptyContainer>
  );
}
