import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const Wrapper = styling("div")((props) => ({
  backgroundColor: Color.backgroundGrey,

  minHeight: "50svh",
  height: props.selectedTab === "for-you" ? "calc(100svh - 68px)" : "100%",
  width: "100%",
  marginTop: props.alertBanner ? "64px" : 0,

  "@media (max-width: 1279px)": {
    height: props.selectedTab === "for-you" ? "calc(100svh - 52px)" : "100%",
    marginTop: props.alertBanner ? "52px" : 0,
  },

  "@media (max-width: 839px)": {
    height: props.selectedTab === "for-you" ? "calc(100svh - 82px)" : "100%",
    marginTop: props.alertBanner ? "82px" : 0,
  },

  "@media (max-width: 640px)": {
    height: props.selectedTab === "for-you" ? "calc(100svh - 62px)" : "100%",
    marginTop: props.alertBanner ? "104px" : 0,

    "@media (max-height: 700px)": {
      height: props.selectedTab === "for-you" ? "calc(100svh - 14px)" : "100%",
      marginTop: props.alertBanner ? "104px" : 0,
    },
  },
}));
