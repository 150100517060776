import useMediaQuery from "@mui/material/useMediaQuery";
import { useClickAway, useIsClient } from "@uidotdev/usehooks";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertIdsToUrl } from "../../../helpers/filter_management";
import {
  fetchSearchSuggestions,
  updateJobSearchKeyword,
} from "../../../redux/actions/job_action";
import JobSearchOptionsMenu from "./JobSearchOptionsMenu/JobSearchOptionsMenu";
import {
  BadgeStyled,
  ClearButton,
  ClearIcon,
  FilterButton,
  FilterIcon,
  InternshipIcon,
  SearchBarWrapper,
  SearchButton,
  SearchDropdownContainer,
  SearchIcon,
  SearchInput,
  SearchOptionsWrapper,
  SearchSuggestionContainer,
  Wrapper,
} from "./styles";

/**
 * JobSearchOptions Component
 * Main search interface for jobs including a search bar, filter button and internship toggle.
 * Provides functionality for searching jobs by keyword and managing filter states.
 * Responsive design that adapts between mobile and desktop views.
 */
export default function JobSearchOptions() {
  const isClient = useIsClient();
  const router = useRouter();
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const isMobile = useMediaQuery("(max-width: 640px)");

  const filterButtonRef = useRef(null);

  const dispatch = useDispatch();

  const isLoadingJobs = useSelector((state) => state.jobs?.isLoadingJobs);

  const jobListFilter = useSelector((state) => state.jobs?.jobListFilter);

  const currentSearch = useSelector((state) => state.jobs?.jobSearchKeyword);
  const searchSuggestion = useSelector((state) => state.jobs?.searchData);

  const specialisationList = useSelector((state) => state.jobs?.specialisation);
  const stateList = useSelector((state) => state.jobs?.stateRegions);

  // Filter Button State
  const [searchBarFocus, setSearchBarFocus] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  //DYNAMIC CONST
  const totalFilter = useMemo(() => {
    const {
      specialisation = [],
      state = [],
      jobType = [],
      experience = [],
      salary = 0,
      globalHire = false,
    } = jobListFilter;

    const total =
      specialisation?.length +
      state?.length +
      jobType?.length +
      experience?.length +
      (salary > 0 ? 1 : 0) +
      (globalHire ? 1 : 0);
    return total;
  }, [jobListFilter]);

  const filterActive = totalFilter > 0 && Object.keys(jobListFilter).length > 0;
  const internshipActive = jobListFilter.jobType?.includes(4);

  const debounceSearchSuggestions = debounce((params) => {
    dispatch(fetchSearchSuggestions(params));
  }, 300);

  // FUNCTION LOGIC
  // SEARCH BAR LOGIC
  // handle search input focus state
  function handleFocusSearch() {
    setSearchBarFocus(true);
  }

  // handle search input changes
  function handleInputSearch(e) {
    const keyword = e.target.value;

    dispatch(updateJobSearchKeyword({ keyword: keyword }));
    debounceSearchSuggestions({ keyword: keyword });
  }

  // handle search suggestion and highlight the matching text
  function boldMatchingText(suggestion) {
    const keyword = currentSearch.toLowerCase();

    const lowerCaseSuggestion = suggestion.toLowerCase();
    const index = lowerCaseSuggestion.indexOf(keyword);

    if (!keyword || index === -1) {
      return (
        <SearchSuggestionContainer
          onClick={() => handleTriggerSearch(suggestion)}
        >
          {suggestion}
        </SearchSuggestionContainer>
      );
    }

    const beforeMatch = suggestion.substring(0, index);
    const match = suggestion.substring(index, index + keyword.length);
    const afterMatch = suggestion.substring(index + keyword.length);

    return (
      <SearchSuggestionContainer
        onClick={() => handleTriggerSearch(suggestion)}
      >
        <span>
          {beforeMatch}
          <b>{match}</b>
          {afterMatch}
        </span>
      </SearchSuggestionContainer>
    );
  }

  // clear the current search input
  function handleClearSearch() {
    dispatch(updateJobSearchKeyword({ keyword: "" }));

    const selectedFilter = {
      ...jobListFilter,
      keyword: "",
    };

    const { path, query } = convertIdsToUrl(
      selectedFilter,
      specialisationList,
      stateList
    );

    router.push({ pathname: path, query }, undefined, { shallow: true });
  }

  // handle enter key press
  function handleEnterSearch(e) {
    if (e.key !== "Enter" || currentSearch === jobListFilter.keyword) {
      return;
    }
    handleTriggerSearch();
  }

  // handle trigger search
  function handleTriggerSearch(suggestion = null) {
    setFilterOpen(false);
    const selectedFilter = {
      ...jobListFilter,
      keyword: suggestion ?? currentSearch,
    };

    const { path, query } = convertIdsToUrl(
      selectedFilter,
      specialisationList,
      stateList
    );

    router.push({ pathname: path, query }, undefined, { shallow: true });
    setSearchBarFocus(false);
  }

  // FILTER LOGIC
  // open and close the filter menu
  function handleClickFilter() {
    setFilterOpen(!filterOpen);
  }

  // apply and remove the internship filter
  function handleClickInternship() {
    if (internshipActive) {
      const newJobListFilter = { ...jobListFilter, jobType: [] };

      const { path, query } = convertIdsToUrl(
        newJobListFilter,
        specialisationList,
        stateList
      );

      router.push({
        pathname: path,
        query: query,
        shallow: true,
      });
    } else {
      const newJobListFilter = { ...jobListFilter, jobType: [4] };

      const { path, query } = convertIdsToUrl(
        newJobListFilter,
        specialisationList,
        stateList
      );

      router.push({
        pathname: path,
        query: query,
        shallow: true,
      });
    }
  }

  // handle apply search
  function handleApplyFilter(selectedFilter) {
    setFilterOpen(false);

    const { path, query } = convertIdsToUrl(
      selectedFilter,
      specialisationList,
      stateList
    );

    router.push({ pathname: path, query }, undefined, { shallow: true });
  }

  // set the search bar focus to false when clicking away
  const searchbarRef = useClickAway(() => {
    setSearchBarFocus(false);
  });

  return isClient ? (
    <Wrapper>
      <SearchOptionsWrapper>
        {isDesktop ? (
          <SearchBarWrapper
            ref={searchbarRef}
            $active={searchBarFocus}
            disabled={isLoadingJobs}
          >
            <SearchInput
              value={currentSearch}
              onChange={handleInputSearch}
              onClick={handleFocusSearch}
              onKeyDown={handleEnterSearch}
              placeholder="Search Jobs"
            />
            {currentSearch && (
              <ClearButton onClick={handleClearSearch}>
                <ClearIcon />
              </ClearButton>
            )}
            <SearchButton onClick={() => handleTriggerSearch()}>
              <SearchIcon />
            </SearchButton>
          </SearchBarWrapper>
        ) : null}
        <BadgeStyled
          badgeContent={totalFilter}
          ref={filterButtonRef}
          max={9}
          color="primary"
        >
          <FilterButton
            ref={filterButtonRef}
            $active={filterActive}
            onClick={handleClickFilter}
            disabled={isLoadingJobs}
          >
            {!isMobile ? "Filter" : null}
            <FilterIcon />
          </FilterButton>
        </BadgeStyled>
        <FilterButton
          $active={internshipActive}
          onClick={handleClickInternship}
          disabled={isLoadingJobs}
        >
          {!isMobile ? "Internship" : null}
          <InternshipIcon />
        </FilterButton>
      </SearchOptionsWrapper>

      {searchBarFocus &&
      currentSearch?.length > 2 &&
      searchSuggestion?.length > 0 ? (
        <SearchDropdownContainer ref={searchbarRef}>
          {searchSuggestion.map((data, index) => {
            return <div key={index}>{boldMatchingText(data.name)}</div>;
          })}
        </SearchDropdownContainer>
      ) : null}

      <JobSearchOptionsMenu
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        anchorRef={filterButtonRef}
        onApply={handleApplyFilter}
      />
    </Wrapper>
  ) : null;
}
