import { useIntersectionObserver } from "@uidotdev/usehooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  convertGptSummary,
  truncateBenefits,
  truncateSkills,
} from "../../../functions/job";
import { formatSalary } from "../../../helpers/conditional_rendering";
import { getCompanyLogoImage } from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  bookmarkJob,
  unBookmarkJob,
  undoHideJob,
  updateFeedbackHideJob,
  updateHideJob,
} from "../../../redux/actions/job_action";
import * as types from "../../../redux/types/job_type";
import {
  BoxContainer,
  CheckboxButton,
  CheckboxContainer,
  CheckboxHover,
  CheckboxIcon,
  CheckedBox,
  CloseButton,
  UncheckedBox,
} from "../styles";
import {
  AiSummaryContainer,
  AiSummaryHeaderText,
  AiSummaryText,
  BookmarkButton,
  CardContainer,
  CardWrapper,
  CloseIcon,
  CompanyLogoImage,
  CompanyNameText,
  DescriptionContainer,
  DetailChip,
  DetailContainer,
  DividerStyled,
  FooterContainer,
  HeaderImage,
  HideButton,
  HideButtonContainer,
  HideContentText,
  HideHeaderText,
  HideIcon,
  HideJobContainer,
  ImageHeaderContainer,
  JobDetailsText,
  JobHeaderContainer,
  JobTitleText,
  LeftArrowIcon,
  MoreDetailButton,
  OptionContainer,
  OptionLabel,
  RadioStyled,
  SaveIcon,
  ScrollContainer,
  ShowMoreText,
  TextButton,
  UnsaveIcon,
} from "./styles";

export default function ForYouJobCard(props) {
  const {
    job,
    index,
    setInView,
    selectedJob,
    handleSelectJob,
    handleHideJob,
    jobDiscovery,
  } = props;

  const [hide, setHide] = useState(false);

  const [ref, entry] = useIntersectionObserver({
    threshold: 1,
  });

  function trackImpressionInView(job, index) {
    const currency = process.env.NEXT_PUBLIC_JSW_GEOLOCATION ? "SGD" : "RM";

    // POSTHOG JOB IMPRESSION TRACKING

    sendTrackingEvent({
      event: "CE_job-impression",
      "job-id": job?.id,
      origin: "fyp_page",
      "job-discovery": jobDiscovery,
      search_term: "",
    });
  }

  useEffect(() => {
    if (entry?.isIntersecting) {
      setInView(index);
      trackImpressionInView(job, index);
    }
  }, [entry?.isIntersecting]);

  return (
    <CardWrapper ref={ref}>
      {hide && (
        <HideJobComponent
          job={job}
          setHide={setHide}
          handleHideJob={handleHideJob}
        />
      )}
      <JobCardComponent
        job={job}
        selectedJob={selectedJob}
        handleSelectJob={handleSelectJob}
        setHide={setHide}
      />
    </CardWrapper>
  );
}

function JobCardComponent(props) {
  const { job, setHide, selectedJob, handleSelectJob } = props;

  const dispatch = useDispatch();

  const [bookmark, setBookmark] = useState(job?.bookmark);
  const [bookmarkLoading, setBookmarkLoading] = useState(false);

  const [showFullSkills, setShowFullSkills] = useState(false);
  const [showFullBenefits, setShowFullBenefits] = useState(false);

  const isSelected = selectedJob?.some((selected) => selected?.id === job?.id);

  const convertedGptSummary = useMemo(() => {
    return convertGptSummary(job);
  }, [job?.gptSummary]);

  const truncatedSkills = useMemo(() => {
    const { fullString, truncated, remainingCount } = truncateSkills(job);

    return showFullSkills || !remainingCount ? (
      fullString
    ) : (
      <>
        {truncated}...{" "}
        <ShowMoreText
          onClick={(e) => {
            e.stopPropagation();
            setShowFullSkills(true);
          }}
        >
          +{remainingCount} more
        </ShowMoreText>
      </>
    );
  }, [job?.skills, showFullSkills]);

  const truncatedBenefits = useMemo(() => {
    const { fullString, truncated, remainingCount } = truncateBenefits(job);

    return showFullBenefits || !remainingCount ? (
      fullString
    ) : (
      <>
        {truncated}...{" "}
        <ShowMoreText
          onClick={(e) => {
            e.stopPropagation();
            setShowFullBenefits(true);
          }}
        >
          +{remainingCount} more
        </ShowMoreText>
      </>
    );
  }, [job?.company?.benefits, showFullBenefits]);

  const company =
    job?.crossedBorder &&
    job?.crossBorderData?.source_country !=
      process.env.NEXT_PUBLIC_JSW_GEOLOCATION.toUpperCase()
      ? job?.crossBorderData?.company
      : job?.company;

  const companyLogo = useMemo(() => {
    return getCompanyLogoImage(company);
  }, [company]);

  const salaryRange = useMemo(() => {
    if (job?.salary === "Undisclosed" || job?.salary === "Undefined")
      return "Undisclosed";

    return formatSalary(job?.salary, job?.crossBorderData);
  }, [job?.salary, job?.crossBorderData]);

  const bookmarkIcon = bookmark ? <UnsaveIcon /> : <SaveIcon />;

  function handleClickCard(e) {
    e.stopPropagation();
    e.preventDefault();

    handleTriggerTrackingEvent();
    handleSelectJob(job);
  }

  function handleClickCheckbox(e) {
    e.stopPropagation();
    e.preventDefault();

    handleTriggerTrackingEvent();
    handleSelectJob(job);
  }

  function handleTriggerTrackingEvent() {
    if (isSelected) {
      sendTrackingEvent({
        event: "CE_bulk_job_deselect_individual",
        origin: "fyp_jlp",
        job_ids: job?.id,
        timestamp: new Date().toISOString(),
        is_selected: "false",
        selection_type: "deselect individual",
      });
    } else {
      sendTrackingEvent({
        event: "CE_bulk_job_select_individual",
        origin: "fyp_jlp",
        job_ids: job?.id,
        timestamp: new Date().toISOString(),
        is_selected: "true",
        selection_type: "select individual",
      });
    }
  }

  function handleBookmarkJob(e) {
    e.stopPropagation();
    e.preventDefault();

    if (bookmarkLoading) {
      return;
    }

    setBookmarkLoading(true);

    // Toggle bookmark status
    if (!bookmark) {
      dispatch(bookmarkJob({ jobId: job?.id })).then((res) => {
        if (res?.type === types.BOOKMARK_JOB_SUCCEED) {
          toast.success("Job bookmarked");
          setBookmark(true);
          setBookmarkLoading(false);
        }
      });
    } else if (bookmark) {
      dispatch(unBookmarkJob({ jobId: job?.id })).then((res) => {
        if (res?.type === types.UNBOOKMARK_JOB_SUCCEED) {
          toast.success("Job unbookmarked");
          setBookmark(false);
          setBookmarkLoading(false);
        }
      });
    }
  }

  function handleViewFullDetails() {
    window.open(`jobs/${job?.slug}`, "_blank");
  }

  function handleHideJob(e) {
    e.stopPropagation();
    e.preventDefault();

    const params = {
      jobId: job?.id,
      rejectionReason: "",
    };

    dispatch(updateHideJob(params)).then((res) => {
      if (res?.type === jobTypes.HIDE_JOB_SUCCESS) {
        sendTrackingEvent({
          event: "CE_job-hide-click-rec-profile",
          "job-id": job?.id,
        });

        setHide(true);
      }
    });
  }

  return (
    <CardContainer onClick={handleClickCard} selected={isSelected}>
      <BoxContainer flexDirection="column">
        <ImageHeaderContainer>
          <BookmarkButton
            onClick={handleBookmarkJob}
            disabled={bookmarkLoading}
          >
            {bookmarkIcon}
          </BookmarkButton>
          <HeaderImage src={companyLogo} alt={company?.name} />
        </ImageHeaderContainer>

        <JobHeaderContainer>
          <BoxContainer
            justifyContent="space-between"
            alignItems="flex-start"
            gap={"0.5rem"}
          >
            <JobTitleText>{job?.title}</JobTitleText>
            <CheckboxContainer>
              <CheckboxButton
                icon={<UncheckedBox />}
                onClick={handleClickCheckbox}
                checked={isSelected}
                checkedIcon={
                  <CheckedBox>
                    <CheckboxIcon />
                  </CheckedBox>
                }
              />
              <CheckboxHover className="hover-check">
                <CheckboxIcon />
              </CheckboxHover>
            </CheckboxContainer>
          </BoxContainer>

          <BoxContainer gap="0.5rem" alignItems="center">
            <CompanyLogoImage src={companyLogo} alt={company?.name} />
            <CompanyNameText>{company?.name}</CompanyNameText>
          </BoxContainer>
        </JobHeaderContainer>

        <DescriptionContainer>
          <ScrollContainer>
            <DetailContainer>
              <DetailChip type="jobType">{job?.jobType}</DetailChip>
              <DetailChip type="state">{job?.stateRegion}</DetailChip>
              <DetailChip type="salary">{salaryRange}</DetailChip>
            </DetailContainer>

            {job?.gptSummary?.length > 0 && (
              <AiSummaryContainer>
                <AiSummaryHeaderText>AI-Generated Summary:</AiSummaryHeaderText>
                <DividerStyled />
                <AiSummaryText>{convertedGptSummary}</AiSummaryText>
              </AiSummaryContainer>
            )}

            {job?.skills?.length > 0 && (
              <JobDetailsText type="skills">
                <b>Skills: </b>
                {truncatedSkills}
              </JobDetailsText>
            )}

            {job?.company?.benefits?.length > 0 && (
              <JobDetailsText type="benefits">
                <b>Benefits: </b>
                {truncatedBenefits}
              </JobDetailsText>
            )}
          </ScrollContainer>
        </DescriptionContainer>
      </BoxContainer>

      <FooterContainer>
        <HideButton onClick={handleHideJob}>
          Hide
          <HideIcon />
        </HideButton>
        <MoreDetailButton onClick={() => handleViewFullDetails()}>
          View Full Details
        </MoreDetailButton>
      </FooterContainer>
    </CardContainer>
  );
}

function HideJobComponent(props) {
  const { job, setHide, handleHideJob } = props;

  const dispatch = useDispatch();

  const [hideReason, setHideReason] = useState(false);
  const [hideReasonValue, setHideReasonValue] = useState("");
  const [hideConfirmation, setHideConfirmation] = useState(false);

  const headerText = useMemo(() => {
    if (hideConfirmation) return "Thank you for your feedback";
    if (hideReason) return "Tell us why";
    return "Job Hidden";
  }, [hideReason, hideConfirmation]);

  const forwardActionText = useMemo(() => {
    if (hideConfirmation) return "Done";
    if (hideReason) return "Confirm";
    return "Tell us why";
  }, [hideReason, hideConfirmation]);

  function handleCloseFeedback() {
    handleHideJob(job);
  }

  function handleUndoHideJob() {
    const params = {
      jobId: job?.id,
    };

    dispatch(undoHideJob(params)).then((res) => {
      res?.type === jobTypes.UNDO_HIDE_JOB_SUCCESS && setHide(false);
    });
  }

  function handleHideReason() {
    setHideReason(!hideReason);
  }

  function handleSelectReason(event) {
    setHideReasonValue(event.target.value);
  }

  function handleHideConfirmation() {
    const params = {
      jobId: job?.id,
      rejectionReason: hideReasonValue,
    };

    dispatch(updateFeedbackHideJob(params)).then((res) => {
      if (res?.type === jobTypes.FEEDBACK_HIDE_JOB_SUCCESS) {
        setHideConfirmation(true);
        setHideReason(false);
        setHideReasonValue("");
      }
    });
  }

  const handleForwardAction = useCallback(() => {
    if (hideConfirmation) return handleHideJob(job);
    if (hideReason) return handleHideConfirmation();
    return handleHideReason();
  }, [hideConfirmation, hideReason]);

  return (
    <HideJobContainer>
      <BoxContainer
        justifyContent="space-between"
        alignItems="center"
        margin={"0 0 0.5rem"}
      >
        <HideHeaderText>
          {!hideReason && !hideConfirmation && <HideIcon />}
          {headerText}
        </HideHeaderText>
        <CloseButton onClick={handleCloseFeedback}>
          <CloseIcon />
        </CloseButton>
      </BoxContainer>
      {!hideReason && (
        <HideContentText>
          This job will not be recommended to you again for 6 months.
        </HideContentText>
      )}

      {hideReason && !hideConfirmation && (
        <OptionContainer
          value={hideReasonValue}
          onChange={(event) => handleSelectReason(event)}
        >
          <OptionLabel
            value="Completely irrelevant to my Profile/Activity"
            control={<RadioStyled />}
            label="Completely irrelevant to my Profile/Activity"
          />
          <OptionLabel
            value="Not the Experience Level I'm looking for"
            control={<RadioStyled />}
            label="Not the Experience Level I'm looking for"
          />
          <OptionLabel
            value="Salary is not what I'm looking for"
            control={<RadioStyled />}
            label="Salary is not what I'm looking for"
          />
          <OptionLabel
            value="Others"
            control={<RadioStyled />}
            label="Others"
          />
        </OptionContainer>
      )}

      {hideReason && hideConfirmation && (
        <HideContentText>We will tune your recommendations.</HideContentText>
      )}

      <HideButtonContainer>
        {!hideReason && (
          <TextButton onClick={handleUndoHideJob}>Undo</TextButton>
        )}
        {hideReason && !hideConfirmation && (
          <TextButton color="purple" onClick={handleHideReason}>
            <LeftArrowIcon />
          </TextButton>
        )}
        <TextButton color="purple" onClick={handleForwardAction}>
          {forwardActionText}
        </TextButton>
      </HideButtonContainer>
    </HideJobContainer>
  );
}
